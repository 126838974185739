import 'core-js/es/array/iterator';
import jQuery from 'jquery';
import ('../components/brokerChoiceModal/brokerChoiceModal');
import {logMessage} from '../js/common/logger';



window.$ = jQuery;

window.jQuery = jQuery;
window.config = { gtmid: 'GFWM' };
window.windowOnLoad = function(callback) {
    if (window.addEventListener) {
        window.addEventListener('load', callback, false);
    }
    else if (window.attachEvent) {
        window.attachEvent('onload', callback);
    }
};


(function($) {


window.isInViewport = function(elem) {
    let elementTop = elem.offset().top;
    let elementBottom = elementTop + elem.outerHeight();

    let viewportTop = $(window).scrollTop();
    let viewportBottom = viewportTop + $(window).height();

    return elementTop < viewportBottom + 100 ;
};

window.readcookie = function (a) {
    a = new RegExp("(?:^| )" + a + "\x3d([^;]*)", "i");
    return (a = document.cookie.match(a)) && 2 == a.length ? a[1] : null;
};

window.geoipcookie = window.readcookie('geoip');

function replaceLoginUrl(){
    $('.btn-login').attr("href", "//trader.fihtrader.com/webpl3/Account/Login/Lang/Arabic");
}

if (window.geoipcookie === 'SA'){
    replaceLoginUrl();
}




    


/* add/remove class active for nav */
function setActiveUrl(){
    var url  = document.location.pathname;
    try {
        url = decodeURIComponent(url);
    } catch(x) {}
    $('.header-bottom ul li').find('active').removeClass('active');

    switch (url){
        case "/" :
            $('.header-bottom ul li.home-url').addClass('active');
            break;
        case "/当社について/iforexが選ばれる理由" :
            $('.header-bottom ul li.why-url').addClass('active');
            break;
        case "/プロモーション":
            $('.header-bottom ul li.promotions-url').addClass('active');
            break;
        case "/商品/銘柄":
            $('.header-bottom ul li.instruments-url').addClass('active');
            break;
        case "/当社について/iforexについて":
            $('.header-bottom ul li.about-url').addClass('active');
            break;
        case "/お取引までの手順":
            $('.header-bottom ul li.getting-url').addClass('active');
            break;
        default:
        // only if case does not exist
    }
}

window.scrollingTasks = [];

function sendTrackingEvent(e){
    
    let elem =  e.target,
    device = window.outerWidth > 768 ? 'desk' : 'mob',
    msg = elem.dataset.name + '_' + device;

    logMessage({eid: msg })
}

//Add tracking via webLogger
function AddTracking(){

    let tracking_items = document.querySelectorAll('.tracking-item');
    let trackingArray = Array.from(tracking_items);

    trackingArray.length > 0 && trackingArray.forEach(item => {
 
        item.addEventListener('click', e => {
            sendTrackingEvent(e)
        });

        item.classList.remove("tracking-item");

    })
}



window.windowOnLoad(function(e) {

    window.__dlw = {
        rc: function(e) {
            var t = new RegExp("(?:^| )" + e + "=([^;]*)", "i"),
                i = document.cookie.match(t);
            return i && 2 === i.length ? decodeURIComponent(i[1]) : null;
        },
        sc: function(e, t, i) {
            var n = "";
            if (i) {
                var l = new Date();
                l.setTime(l.getTime() + 24 * (void 0 !== i ? i : 3) * 60 * 60 * 1e3), n = "; expires=" + l.toGMTString()
            }
            document.cookie = e + "=" + encodeURIComponent(t) + n + "; path=/;";
        },
        store: function(e, t, i) {
            i = i || 3;
            var n = {};
            if (n[e] = t, dataLayer.push(n), 3 !== i) {
                var l = window.__dlw.rc("cdl" + i),
                    r = [];
                if (null != l)
                    for (var c = (r = l.split(";")).length; c--;)
                        if (r[c].split(":")[0] === e) {
                            r.splice(c, 1);
                            break;
                        }
                r.push(e + ":" + t.replace(/;/g, "")); window.__dlw.sc("cdl" + i, r.join(";"), 1 == i && 1e3);
            }
        },
        init: function() {
            var e = (window.__dlw.rc("cdl1") || "") + ";" + (window.__dlw.rc("cdl2") || "");
            if (";" !== e) {
                let n;
                for (let t = e.split(";"), n = {}, i = t.length; i--;) "" !== t[i] && (n[t[i].split(":")[0]] = t[i].split(":")[1]);
                dataLayer.push(n);
            }
        }
    };
    window.__dlw.init();

    window.dataLayer.push({
        'gtm.start':
            new Date().getTime(), event: 'gtm.js'
    });
    const script = document.createElement('script');
    document.body.appendChild(script);
    script.async = true;
    script.defer = true;
    script.src = getparam && getparam("gtm_debug") ? 'https://www.googletagmanager.com/gtm.js?id=GTM-GFWM' : 'https://www.iforex.jpn.com/gtm.js?id=GTM-GFWM' ;


    setActiveUrl();

    if(!$('#page').hasClass('live-rates-page')){
        $(document).on('click', '.nav-tabs li', function (e) {
            e.preventDefault();
            $(this).parent().find('li').removeClass('active');
            $(this).addClass('active');
            var id = $(this).find('a').attr('href');
            $(this).parent().siblings().find('.tab-pane').removeClass('active');
            $(this).parent().siblings().find(id).addClass('active');


            if ($(this).parent().attr('id') === 'live-rates-tabs' ){
                var category = $(this).find('a').attr('href').replace('#', '');
                $('#' + category + ' table').addClass('active');
                //$.fxRates.init(".autoFxRates .tab-pane.active")

            }

        });
    }
        if (document.location.hash !== '' && $(document.location.hash).length > 0) {
            
            var hash = document.location.hash;
            let elem_position = $(hash).offset().top - $('.main-header').height() -50 ;
            

            $('html, body').animate({
                scrollTop: elem_position 
            }, 1e3);
        }
  

        AddTracking();



});


let v2popup = $('.v2PopUp');
let main_banner = $('.main-banner');
let main_header = $('.main-header');
let page = $('#page');
let scrolling = false;



if (window.matchMedia("(min-width: 993px)").matches) {
    let stickyElem = $('.sticky-elem');
    stickyElem.css('top', main_header.outerHeight() + 10 );
}

/* for any scroll with # by click */
$('.scroll-to').on('click', function(){
    var hash = this.hash;
    $('html, body').animate({
        scrollTop: $(hash).offset().top - $('.main-header').height() 
     }, 1e3);
});

/*toogle dropdwon text*/
$('.dropdown-container__main').click(function () {
    if( $(this).hasClass('blue-bg')){
        $(this).removeClass('blue-bg');
        $(this).next('#page.bonus-interest .dropdown-container__main__droptext').slideToggle();
    }else{
        $(this).addClass('blue-bg');
        $(this).next('#page.bonus-interest .dropdown-container__main__droptext').slideToggle();
    }
});

    function setStickyFormOff (){
        $('.form-container').css({'position':'absolute',
         'position':'relative' , 
        'top':'auto' , 
        'bottom' : 'auto' , 
        'left':'auto' , 
        'z-index':'1',
        'max-width':'100%'});
    }

    
$(document).on('click', '.panel-default .panel-heading', function (e) {	
        var parent = $(this).parent();
    if(!$(parent).find('.panel-collapse').hasClass('in')){

        $('.panel-heading').addClass('collapsed');
        $(parent).find('.panel-heading').removeClass('collapsed');
        $('.panel-collapse.in').removeClass('in');
        $(parent).find('.panel-collapse').addClass('in').slideDown(1000);
        

        $([document.documentElement, document.body]).animate({
            scrollTop: $(parent).offset().top - $('.main-header').height() -10
        }, 1000);

    }else{

        $('.panel-collapse.in').removeClass('in').slideUp(1000);
        $(parent).find('.panel-heading').addClass('collapsed');

        
        //set form not sticky if the open panel is longer then the form (at the and of the page)
        if (window.matchMedia("(min-width: 993px)").matches) {
            if($('.form-container').hasClass('long-panel-end-sticky-form') ){
                setStickyFormOff ();
            }
        }
    }
    });


let dropdown_toggle = $('.dropdown-toggle');

dropdown_toggle.click(function(){
    $(this).next().slideToggle('slow');
    $(this).toggleClass('open');
});
    
$(document).on('click', function(event) {
    if (!$(event.target).closest(".dropdown-toggle").length && dropdown_toggle.hasClass('open') && !$(event.target).hasClass('default-lang')) {

        dropdown_toggle.next().slideToggle('slow');
        dropdown_toggle.removeClass('open');
    }
});
    
    
$('.navbar-toggle, .close_mob_menu').click(function(){
    var options = { direction: 'left' };
    $('#slidemenu').toggleClass('open');
    $('body').toggleClass('overflow-hidden');

    dropdown_toggle.next().hide();
        dropdown_toggle.removeClass('open');
    
});


function lazyImageBF(){
    var lazyImages = [].slice.call(document.getElementsByClassName("lazy-image-bf"));
    lazyImages.forEach.call(lazyImages, function (lazyImage) {
        lazyImage.src = lazyImage.dataset.src;
        lazyImage.classList.remove("lazy-image-bf");
    });
}

let contact_us_wrapper = $('.contact-us-strip-wrapper');
let advantage_wrapper= $('.advantage-wrapper');
let loadingFooter = false, footerLoaded = false, loadingAdvantages = false , advantagesLoaded = false;

//Load Lazy images on scroll that are bellow the fold  with class lazy-image-bf
let lazyImage_wrapper = $('.lazy-image-bf');
let loadingLazyImagesBF = false, lazyImagesBFLoaded = false;

function doScrolling() {

    if (!loadingFooter && contact_us_wrapper.length > 0 && contact_us_wrapper.html().trim() === '' && window.isInViewport(contact_us_wrapper)) {
        loadingFooter = true;
        !footerLoaded && import(/* webpackChunkName: "footer" */'../components/footer')
            .then(module => {
                loadingFooter = false;
                footerLoaded = true;
                module.default();
                AddTracking();
            });
    }

    if (!loadingAdvantages && advantage_wrapper.length > 0 && window.isInViewport(advantage_wrapper)) {
        loadingAdvantages = true;
        !advantagesLoaded && import(/* webpackChunkName: "advantage" */'../components/advantage/advantage')
            .then(module => {
                loadingAdvantages = false;
                advantagesLoaded = true;
                module.default();
            });
    }
    //Load Lazy images on scroll that are bellow the fold  with class lazy-image-bf

    if (!loadingLazyImagesBF && !lazyImagesBFLoaded && lazyImage_wrapper.length > 0 && window.isInViewport(lazyImage_wrapper)) {
        loadingLazyImagesBF = true;
        if(!lazyImagesBFLoaded) { 
            lazyImageBF();
            loadingLazyImagesBF = false;
            lazyImagesBFLoaded = true;
        }
    }
}

window.scrollingTasks.push(doScrolling);


if($(window).width() > 620){
    lazyImageBF();
    loadingLazyImagesBF = false;
    lazyImagesBFLoaded = true;
}




function scrollingTimer() {
    if ($(document).height() <= $(window).height()) {
        scrolling = true;
    }
    if($(window).scrollTop()>1){
        scrolling = true;
    }
    if(window.callDoScrolling) scrolling = true;
    // if (scrolling) doScrolling();

    if (scrolling) window.scrollingTasks.forEach(function (task) {
        task();
    });
    window.setTimeout(scrollingTimer, 250);
}

window.startChat = function(t){
    if(t == "chat"){
        dataLayer.push({"event":"start-livechat"});
    }

};



    
  



scrollingTimer();

$(window).on('scroll resize', () => { scrolling = true; } );



})(window.jQuery);