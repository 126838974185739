export function logMessage(message) {
    const payload = {
        "log-message": Object.assign(message, {uid: window.promocode, s: window.serial})
    };

    const payloadStr = JSON.stringify(payload);

    const logger_url = 'https://content.webapi-services.net/api/webLogger';

    try {
        navigator.sendBeacon(logger_url, payloadStr);
    } catch(x) {
        console.error(x);
        const xhr = new XMLHttpRequest();
        xhr.open('POST', logger_url, true);
        xhr.setRequestHeader('Content-Type', 'applicaton/json');
        xhr.send(payloadStr);
    }
}